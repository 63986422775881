import { IRentalLeasing } from "@/models/RentalLeasing";
import LeasingsService from "@/services/LeasingsService";

const pastLeasing: IRentalLeasing = {
  leasingId: 1,
  status: 2,
  lastStatusChangedAt: "2000-01-01T00:00:00.000000Z",
  leasingContractValidityDate: "2000-01-01T00:00:00.000000Z",
  leasingContractSignatureDate: "2000-01-01T00:00:00.000000Z",
  leasingTerminationDate: "2000-12-31T00:00:00.000000Z",
  monthlyPrice: 90000,
};

const currentLeasingWithoutTerminationDate: IRentalLeasing = {
  leasingId: 1,
  status: 2,
  lastStatusChangedAt: "2023-01-01T00:00:00.000000Z",
  leasingContractValidityDate: "2023-01-01T00:00:00.000000Z",
  leasingContractSignatureDate: "2023-01-01T00:00:00.000000Z",
  leasingTerminationDate: null,
  monthlyPrice: 90000,
};

const currentLeasingWithFutureTerminationDate: IRentalLeasing = {
  leasingId: 1,
  status: 2,
  lastStatusChangedAt: "2023-01-01T00:00:00.000000Z",
  leasingContractValidityDate: "2023-01-01T00:00:00.000000Z",
  leasingContractSignatureDate: "2023-01-01T00:00:00.000000Z",
  leasingTerminationDate: "2999-12-31T00:00:00.000000Z",
  monthlyPrice: 90000,
};

const futureLeasing: IRentalLeasing = {
  leasingId: 1,
  status: 2,
  lastStatusChangedAt: "2999-01-01T00:00:00.000000Z",
  leasingContractValidityDate: "2999-01-01T00:00:00.000000Z",
  leasingContractSignatureDate: "2999-01-01T00:00:00.000000Z",
  leasingTerminationDate: null,
  monthlyPrice: 90000,
};

describe("LeasingsService", () => {
  beforeEach(() => {
    jest.restoreAllMocks();
  });

  describe("sortLeasings", () => {
    it("should return sorted leasings by higher contract validity date", () => {
      // Given
      const leasings = [
        pastLeasing,
        currentLeasingWithFutureTerminationDate,
        futureLeasing,
      ];
      const expected = [
        futureLeasing,
        currentLeasingWithFutureTerminationDate,
        pastLeasing,
      ];
      // When
      const result = LeasingsService.sortLeasings(leasings);
      // Then
      expect(result).toStrictEqual(expected);
    });
  });

  describe("getPastLeasings", () => {
    it("should return past leasings by termination date", () => {
      // Given
      const leasings = [
        pastLeasing,
        currentLeasingWithoutTerminationDate,
        currentLeasingWithFutureTerminationDate,
        futureLeasing,
      ];
      // When
      const result = LeasingsService.getPastLeasings(leasings);
      // Then
      expect(result).toStrictEqual([pastLeasing]);
    });
  });

  describe("isCurrentLeasing", () => {
    it("should return if leasing is current (past or current validity date & future, current or undefined termination date)", () => {
      // Given
      // When
      // Then
      expect(LeasingsService.isCurrentLeasing(pastLeasing)).toBe(false);
      expect(LeasingsService.isCurrentLeasing(futureLeasing)).toBe(false);
      expect(
        LeasingsService.isCurrentLeasing(currentLeasingWithoutTerminationDate)
      ).toBe(true);
      expect(
        LeasingsService.isCurrentLeasing(
          currentLeasingWithFutureTerminationDate
        )
      ).toBe(true);
    });
  });

  describe("getCurrentLeasing", () => {
    it("should return current leasing (past or current validity date & future, current or undefined termination date)", () => {
      // Given
      const leasings = [
        pastLeasing,
        futureLeasing,
        currentLeasingWithFutureTerminationDate,
      ];
      // When
      const result = LeasingsService.getCurrentLeasing(leasings);
      // Then
      expect(result).toStrictEqual(currentLeasingWithFutureTerminationDate);
    });
  });
});
